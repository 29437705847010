import { defineStore } from 'pinia';

export const useStore = defineStore('store', {
    state: () => ({
        search_lists: []
    }),
    actions: {
        addToList(school) {
            const exists = this.search_lists.some(item => item.name === school.name);

            if (!exists) {
                this.search_lists.push(school);
            }
        },
        removeList(index) {
            this.search_lists.splice(index, 1);
        }
    },
    persist: {
        storage: persistedState.localStorage,
    }
});
