<template>
  <div>
    <!-- Hero Section -->
    <div class="hero">
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12" lg="8" class="text-center">
            <img class="py-5"
                 width="180"
                 src="https://averoes-chelles.org/img/logo-white.svg"
                 alt="Eduworky Logo"
            />

            <h1 class="text-white font-weight-bold hero-title">
              Eduworky : La solution tout-en-un pour la gestion des associations et écoles
            </h1>

            <p class="text-white py-5" :style="{opacity:'0.8'}">
              Eduworky, la solution incontournable pour les associations et les écoles.
              Simplifiez votre gestion administrative et dynamisez l'engagement des membres grâce à une plateforme intuitive, conçue pour répondre à vos besoins.
            </p>

            <!-- Search Input -->
            <v-text-field v-model="keyword"
                          class="rounded-lg"
                          prepend-inner-icon="mdi-magnify"
                          flat
                          variant="solo"
                          color="primary"
                          bg-color="white"
                          placeholder="Commencez à taper pour afficher les résultats..."
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Schools List Section -->
    <div class="bg-grey-lighten-5">
      <v-container>
        <v-row>
          <v-col cols="12" lg="4" v-for="(item, i) in filteredSchools" :key="i">
            <v-card class="shadow border rounded-lg" variant="flat">
              <div @click="[dialog = true,school = item]" class="cursor-pointer">
                <v-card-title class="d-flex align-center justify-space-between">
                  <v-avatar tile rounded size="70">
                    <img width="100%" :alt="item.name" :src="item.logo">
                  </v-avatar>

                  <v-tooltip text="Supprimer">
                    <template v-slot:activator="{ props }">
                      <v-btn icon="mdi-delete"
                             color="red"
                             v-bind="props"
                             variant="text"
                             v-if="isInSearchList(item)"
                             @click.stop="removeFromList(item)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>

                </v-card-title>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="10">
                      <h3>{{ item.name }}</h3>
                      <p class="text-grey-darken-1">{{ item.description }}</p>
                    </v-col>
                    <v-col cols="2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="600">
      <v-card class="transform-none">

        <v-card-title class="d-flex justify-space-between align-center">
          Mode de connexion
          <v-spacer/>
          <v-btn icon="mdi-close" color="red" variant="text" @click="dialog = false"></v-btn>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-list :lines="'two'" slim>

            <v-list-item @click="openUrl(school.admin_link,school)" class="rounded">
              <template v-slot:prepend>
                <v-icon color="purple" size="30">mdi-account-tie</v-icon>
              </template>
              <v-list-item-title class="font-weight-medium">Administration</v-list-item-title>
              <v-list-item-subtitle>Accédez à votre Portail de Gestion Scolaire </v-list-item-subtitle>
            </v-list-item>

            <v-list-item @click="openUrl(school.parent_link,school)" class="rounded" density="compact">
              <template v-slot:prepend>
                <v-icon color="green" size="30">mdi-account-child</v-icon>
              </template>
              <v-list-item-title class="font-weight-medium">Parent</v-list-item-title>
              <v-list-item-subtitle>Suivez les Progrès de Votre Enfant en Temps Réel </v-list-item-subtitle>
            </v-list-item>

            <v-list-item @click="openUrl(school.student_link,school)" class="rounded" density="compact">
              <template v-slot:prepend>
                <v-icon color="blue" size="30">mdi-school</v-icon>
              </template>
              <v-list-item-title class="font-weight-medium">Élève</v-list-item-title>
              <v-list-item-subtitle>Connectez-vous à Votre Espace Élève</v-list-item-subtitle>
            </v-list-item>

          </v-list>
        </v-card-text>

      </v-card>
    </v-dialog>


  </div>
</template>

<script setup>
import {ref, computed} from 'vue';
import {useStore} from "../../stores/index.js";

const dialog = ref(false);
const school = ref({});
const store = useStore()

const keyword = ref("");
const schools = ref([

  {
    name: "Averoes chelles",
    email: "contact@averoes-chelles.fr",
    phone: "06 67 46 57 60",
    logo: new URL(`/assets/images/averoes.png`, import.meta.url).href,
    admin_link: "https://averoes-chelles.org/admin",
    parent_link: "https://averoes-chelles.org/auth/login?tab=parent",
    student_link: "https://averoes-chelles.org/auth/login?tab=student",
    description: "2, ter rue de la Briqueterie 77500 Chelles",
  },

  {
    name: "UAMG",
    email: "ecole.uamg@gmail.com",
    phone: "07.83.21.96.32",
    logo: new URL(`/assets/images/uamg.png`, import.meta.url).href,
    admin_link: "https://uamg.fr/admin",
    parent_link: "https://uamg.fr/auth/login?tab=parent",
    student_link: "https://uamg.fr/auth/login?tab=student",
    description: "Umag 126 Rue Jules Guesde 93220 - Gagny",
  },

  {
    name: "AGIR+",
    email: "secretariatagirplus@gmail.com",
    phone: "07.68.26.64.82",
    logo: new URL(`/assets/images/agirplus.png`, import.meta.url).href,
    admin_link: "https://agirplus.org/admin",
    parent_link: "https://agirplus.org/auth/login?tab=parent",
    student_link: "https://agirplus.org/auth/login?tab=student",
    description: "AGIR+ ,8 rue Conrad Adenauer,93110 Rosny-sous-Boisy",
  },
  {
    name: "Centre des lumieres.",
    email: "culturel@centre-des-lumieres.fr",
    phone: "06.72.23.00.00",
    logo: new URL(`/assets/images/centre-des-lumieres.jpeg`, import.meta.url).href,
    admin_link: "https://inscriptions.centre-des-lumieres.fr/admin",
    parent_link: "https://inscriptions.centre-des-lumieres.fr/auth/login?tab=parent",
    student_link: "https://inscriptions.centre-des-lumieres.fr/auth/login?tab=student",
    description: "06 Rue Galilee Zac de la Vilette aux aulnes 77290 mitry-mory",
  },
]);

const filteredSchools = computed(() => {

  if (keyword.value && keyword.value.length >= 4) {
    const searchQuery = keyword.value.toLowerCase();
    return schools.value.filter(school =>
        school.name.toLowerCase().includes(searchQuery) ||
        school.description.toLowerCase().includes(searchQuery) ||
        school.email.toLowerCase().includes(searchQuery) ||
        school.admin_link.toLowerCase().includes(searchQuery) ||
        school.parent_link.toLowerCase().includes(searchQuery) ||
        school.student_link.toLowerCase().includes(searchQuery) ||
        school.phone.toLowerCase().includes(searchQuery)
    );
  }

  if (store.search_lists.length > 0) {
    return schools.value.filter(school =>
        store.search_lists.some(item => item.name === school.name)
    );
  }

  return [];
});


const isInSearchList = (school) => {
  return store.search_lists.some(item => item.name === school.name);
};

const openUrl = (url, school) => {
  store.addToList(school);
  window.open(url, '_blank');
};

const removeFromList = (school) => {
  const index = store.search_lists.findIndex(item => item.name === school.name);
  if (index !== -1) {
    store.removeList(index);
  }
};
</script>

<style scoped>
.hero {
  min-height: 60vh;
  background-size: cover;
  background-position: center;
  background-image: url("@/assets/images/cover.jpg");
}

@media (max-width: 600px) {
  .hero-title {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  .hero-title {
    font-size: 45px;
  }
}
</style>
